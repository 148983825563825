import React, { useContext } from 'react';

import GlobalContext from '../../context/global-context';

import './aboutInfo.scss';

const AboutInfo: React.FC = () => {
  const { i18n } = useContext(GlobalContext);
  return (
    <div className="about-info">
      <h3>{i18n?.t('about.title')}</h3>
      <p>{i18n?.t('about.body')}</p>
    </div>
  );
};

export default AboutInfo;
